import icon from '../../img/underconstruction.svg'
import sty from './underConstruction.module.css'
function UnderConstruction() {
    return ( 
        <div className={sty.underConstruction_img}>
           <object type="image/svg+xml" data={icon}>svg-animation</object>
        </div>
     );
}

export default UnderConstruction;