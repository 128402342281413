import './App.css';
import UnderConstruction from './components/underConstruction';

function App() {
  return (
    <UnderConstruction/>
  )
}

export default App;
